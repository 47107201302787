$(function () {
	$('a[href^="#"]').click(function () {
		var speed = 500;
		var href = $(this).attr("href");
		if (href == '#') return false;
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top;
		$("html, body").animate({ scrollTop: position }, speed, "swing");
		return false;
	});

	var s = {
		pagetop: $(".js-pagetop")
	}
	$(window).scroll(function () {
		100 < $(window).scrollTop() ? s.pagetop.stop().fadeTo(300, 1) : s.pagetop.stop().fadeTo(200, 0, function () {
			s.pagetop.hide()
		})
	}).scroll();
});